<template>
  <div class="lz-container">
    <LzNewsFilter :lang="lang" @filter-applied="debouncedHandleFilter" />
    
    <div class="news-wrapper lz-mt-16">
      <div v-if="newsItems.length > 0" class="filterList newsList">
        <LzNewsItem v-for="newsItem in newsItems" :key="newsItem.id" v-bind="newsItem" />
      </div>
      <p v-else>{{ noResultsLabel }}</p>
      <div v-if="isLoading && isScrolling" class="lz-mt-16 lz-flex lz-justify-center">
        <LzSpinner />
      </div>
    </div>

    
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onBeforeUnmount, provide } from 'vue';
  import LzNewsItem from './LzNewsItem.vue';
  import LzNewsFilter from './LzNewsFilter.vue';
  import LzSpinner from '../base/LzSpinner.vue';
  import { checkIsMobile, newsRestEndpoint } from '@/utils';

  const props = defineProps<{
    lang: string;
    labelDone: string;
    labelClear: string;
    labelClearAll: string;
    labelCategory: string;
    labelYear: string;
    labelFilter: string;
    filterTitle: string;
    noResultsLabel: string;
  }>();

  const newsItems = ref([]);
  const limit = 30;
  const offset = ref(0);
  const isLoading = ref(false);
  const isScrolling = ref(false);
  const hasMoreItems = ref(true);
  const selectedCategories = ref<string[]>([]);
  const selectedYears = ref<string[]>([]);
  const isMobile = ref(checkIsMobile());

  provide('lang', props.lang);
  provide('isMobile', isMobile);
  provide('translations', {
    general: {
      doneLabel: props.labelDone,
      clearLabel: props.labelClear,
      clearAllLabel: props.labelClearAll
    },
    filters: {
      categoryLabel: props.labelCategory,
      yearLabel: props.labelYear,
      filterLabel: props.labelFilter,
      filterTitle: props.filterTitle
    }
  });

  async function fetchNewsItems(categories?: string[], years?: string[], reset = false) {
    if (isLoading.value || !hasMoreItems.value) return;

    isLoading.value = true;
    const url = buildUrl(categories, years);

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const items = await response.json() || [];
      updateNewsItems(items, reset);

    } catch (error) {
      console.error('Error fetching news items:', error);
    } finally {
      isLoading.value = false;
    }
  }

  function buildUrl(categories?: string[], years?: string[]): string {
    const baseUrl = `${newsRestEndpoint}/items/${props.lang}`;
    const params = new URLSearchParams({
      limit: limit.toString(),
      offset: offset.value.toString(),
    });

    categories?.forEach(category => params.append('category', category));
    years?.forEach(year => params.append('year', year));

    return `${baseUrl}?${params.toString()}`;
  }

  function updateNewsItems(items, reset) {
    if (reset) {
      newsItems.value = items;
      offset.value = limit;
    } else {
      newsItems.value.push(...items);
      offset.value += limit;
    }
    hasMoreItems.value = items.length === limit;
  }

  const debouncedHandleFilter = debounce((filters) => {
    selectedCategories.value = filters.categories;
    selectedYears.value = filters.years;
    resetPagination();
    fetchNewsItems(selectedCategories.value, selectedYears.value, true);
  }, 500);

  function resetPagination() {
    offset.value = 0;
    hasMoreItems.value = true;
  }

  function debounce(fn, delay: number) {
    let timeoutID: number | undefined;
    return (...args: unknown[]) => {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => fn(...args), delay);
    };
  }

  function handleScroll() {
    const { scrollTop, scrollHeight } = document.documentElement;
    const bottomOfWindow = scrollTop + window.innerHeight >= scrollHeight - 50;
    isScrolling.value = true;
    if (bottomOfWindow) {
      fetchNewsItems(selectedCategories.value, selectedYears.value);
    }
  }

  function resetScroll() {
    isScrolling.value = false;
  }

  function windowResized() {
    isMobile.value = checkIsMobile();
  }

  onMounted(() => {
    fetchNewsItems();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scrollend', resetScroll);
    window.addEventListener('resize', windowResized);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('scrollend', resetScroll);
    window.removeEventListener('resize', windowResized);
  });
</script>
