<template>
  <div class="lz-relative lz-inline-block dropdown-area" ref="dropdown" @click.stop>
    <button @click="toggleDropdown"
      class="lz-bg-white lz-border lz-border-solid lz-flex lz-h-[55px] lz-items-center lz-justify-between lz-px-4 lz-rounded-[27.5px] lz-w-[195px]"
      :class="[hasSelected ? 'lz-border-black' : 'lz-border-gray-500']">
      {{ label }}
      <span class="lz-transform lz-transition-transform lz-flex lz-items-center" :class="{ 'lz-rotate-180': isOpen }">
        <lz-icon name="mini-arrow" class="lz-w-5 lz-h-5" />
      </span>
    </button>
    <div v-if="isOpen"
      class="lz-absolute lz-w-[325px] lz-h-[335px] lz-py-6 lz-mt-2 lz-bg-white lz-border lz-border-gray-300 lz-rounded-md lz-shadow-lg lz-z-10">
      <div
        class="lz-overflow-y-auto lz-h-[242px] lz-border-b lz-border-t-0 lz-border-x-0 lz-border-b-gray lz-border-solid ">
        <div v-for="item in items" :key="itemKey(item)" class="lz-py-2 lz-px-8 hover:lz-bg-gray-100">
          <label class="lz-flex lz-items-center lz-m-0 lz-cursor-pointer">
            <input type="checkbox" :value="itemKey(item)" v-model="internalSelectedItems" class="custom-checkbox" />
            <p class="lz-m-0 lz-ml-5">{{ item.title || item }}</p>
          </label>
        </div>
      </div>
      <div class="lz-w-full lz-flex lz-justify-between lz-px-8 ">
        <button @click="clearFilter"
          class="lz-font-semibold lz-leading-[4.3] lz-text-[#808b8f] lz-bg-none lz-border-none lz-p-0 lz-cursor-pointer">
          {{ t.general.clearLabel }}
        </button>
        <button @click="applyFilter"
          class="lz-font-semibold lz-leading-[4.3] lz-text-[#e5251f] lz-bg-none lz-border-none lz-p-0 lz-cursor-pointer">
          {{ t.general.doneLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch, onMounted, onBeforeUnmount, inject } from 'vue';

  const props = defineProps({
    name: String,
    label: String,
    items: Array,
    selectedItems: Array,
    itemKey: Function,
    isOpen: Boolean
  });

  const emit = defineEmits(['update:selectedItems', 'applyFilter', 'toggle']);

  const hasSelected = ref(false);
  const internalSelectedItems = ref([...props.selectedItems]);
  const dropdown = ref(null);
  const t = inject('translations');

  watch(() => props.selectedItems, (newVal) => {
    internalSelectedItems.value = [...newVal];
    hasSelected.value = newVal.length > 0;
  });

  watch(() => props.isOpen, (newValue) => {
     if (!newValue) {
         emit('update:selectedItems', [...internalSelectedItems.value]);
         emit('applyFilter');
     }
  });

  const toggleDropdown = () => {
    emit('toggle');
  };

  const applyFilter = () => {
    emit('update:selectedItems', [...internalSelectedItems.value]);
    emit('applyFilter');
    emit('toggle');
  };

  const clearFilter = () => {
    internalSelectedItems.value = [];
    emit('update:selectedItems', []);
    emit('applyFilter');
    emit('toggle');
  };

  const handleClickOutside = (event) => {
    if (dropdown.value && !dropdown.value.contains(event.target)) {
      applyFilter();
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });
</script>
