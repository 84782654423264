<template>
  <div v-if="!isMobileComputed"  ref="filterArea">
    <div class="lz-flex lz-items-center">
      <LzMultiSelectDropdown
        :label="t.filters.yearLabel"
        :items="years"
        v-model:selectedItems="selectedYears"
        @applyFilter="applyFilter"
        :isOpen="yearDropdownOpen"
        @toggle="handleToggle('year')"
        :itemKey="yearKey"
        class="lz-inline-block"
      />
      <LzMultiSelectDropdown
        :label="t.filters.categoryLabel"
        :items="categories"
        v-model:selectedItems="selectedCategories"
        @applyFilter="applyFilter"
        :isOpen="categoryDropdownOpen"
        @toggle="handleToggle('category')"
        :itemKey="categoryKey"
        class="lz-inline-block lz-ml-4"
      />

      <span
        v-if="hasFilters"
        class="lz-flex lz-items-center lz-font-semibold lz-text-[#808b8e] lz-px-4 lz-ml-6 lz-cursor-pointer"
        @click="removeAllFilters"
      >
<!--        <lz-icon name="mini-arrow" class="lz-h-6 lz-w-6 lz-mr-2" />-->
        <span class="lz-mr-1 lz-text-[1.4rem]/[1.75rem]">&times;</span>
        {{ t.general.clearAllLabel }}
      </span>
    </div>

    <div class="lz-flex lz-mt-4 lz-flex-wrap">
      <LzChip
        :items="selectedYears"
        @removeItem="removeFilter('years', $event)"
      />
      <LzChip
        :items="selectedCategories"
        :getItemTitle="getCategoryTitleById"
        @removeItem="removeFilter('categories', $event)"
      />
    </div>
  </div>

  <div v-else class="filter-area-mobile">
    <LzModalFilter
      :label="t.filters.filterLabel"
      :yearItems="years"
      v-model:selectedYearItems="selectedYears"
      @applyFilter="applyFilter"
      :itemYearKey="yearKey"
      :categoryItems="categories"
      v-model:selectedCategoryItems="selectedCategories"
      :itemCategoryKey="categoryKey"
      class="lz-inline-block"
    />
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, onBeforeUnmount, computed, inject } from 'vue';
  import LzMultiSelectDropdown from '@/components/base/LzMultiSelectDropdown.vue';
  import LzChip from '@/components/base/LzChip.vue';
  import LzIcon from '@/components/base/LzIcon.vue';
  import LzModalFilter from '../base/LzModalFilter.vue';
  import { newsRestEndpoint } from '@/utils';

  const emit = defineEmits(['filter-applied']);

  const years = ref([]);
  const categories = ref([]);
  const selectedYears = ref([]);
  const selectedCategories = ref([]);

  const yearDropdownOpen = ref(false);
  const categoryDropdownOpen = ref(false);

  const lang = inject<string>('lang');
  const isMobile = inject<boolean>('isMobile');
  const t = inject('translations');

  const isMobileComputed = computed(() => isMobile.value);

  const yearKey = (year: number) => year;
  const categoryKey = (category: { id: string }) => category.id;

  const hasFilters = computed(() => selectedYears.value.length > 0 || selectedCategories.value.length > 0);

  function handleToggle(type: 'year' | 'category') {
    if (type === 'year') {
      yearDropdownOpen.value = !yearDropdownOpen.value;
      categoryDropdownOpen.value = false; 
    } else if (type === 'category') {
      categoryDropdownOpen.value = !categoryDropdownOpen.value;
      yearDropdownOpen.value = false;
    }
  }

  function handleClickOutside(event) {
    const filterArea = document.querySelector('.dropdown-area');
    if (filterArea && !filterArea.contains(event.target)) {
      yearDropdownOpen.value = false;
      categoryDropdownOpen.value = false;
    }
  }

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });

  onMounted(async () => {
    await fetchFilters();
    applyFiltersFromUrl();
  });

  async function fetchFilters() {
    try {
      const response = await fetch(`${newsRestEndpoint}/filters/${lang}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      years.value = data.years;
      categories.value = data.categories;
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }

  function applyFilter() {
    emit('filter-applied', {
      years: selectedYears.value,
      categories: selectedCategories.value,
    });
  }

  function removeFilter(type: 'years' | 'categories', value: number | string) {
    if (type === 'years') {
      selectedYears.value = selectedYears.value.filter((year) => year !== value);
    } else if (type === 'categories') {
      selectedCategories.value = selectedCategories.value.filter((category) => category !== value);
    }
    applyFilter();
  }

  function removeAllFilters() {
    selectedYears.value = [];
    selectedCategories.value = [];
    applyFilter();
  }

  function getCategoryTitleById(categoryId: string): string {
    const category = categories.value.find((cat) => cat.id === categoryId);
    return category ? category.title : '';
  }

  function applyFiltersFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const year = urlParams.getAll('year');
    const categoryTitles = urlParams.getAll('category');

    if (year.length > 0) {
      selectedYears.value = years.value.filter((y) => year.includes(String(y)));
    }

    if (categoryTitles.length > 0) {
      selectedCategories.value = categories.value
        .filter((cat) => categoryTitles.includes(cat.title.toLowerCase()))
        .map((cat) => cat.id);
    }

    applyFilter();
  }
</script>
