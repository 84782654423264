<template>
    <div :class="formattedClass">
      <div class="col-md-4 left">
        {{ formattedDate }}
      </div>
      <div class="col-md-8 right">
        <a class="news-title" :href="link">
          <h3>{{ title }}</h3>
        </a>
        <p v-html="abstractText"></p>
        <div class="news-links">
          <ul>
            <li v-for="(relatedLink, index) in relatedLinks" :key="index">
              <span class="internal">
                <a
                  :href="relatedLink.href"
                  :target="relatedLink.target"
                  data-analytics-event-name="link_click"
                  data-analytics-event-category="Link"
                  :data-analytics-element-target="relatedLink.href"
                  :data-analytics-element-title="relatedLink.title"
                  data-analytics-element-type="News"
                >
                  {{ relatedLink.title }}
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
    import { computed } from 'vue';
    import { NewsItem } from '@/types';
    
    const props = defineProps<NewsItem>();
    
    const formatDate = (timestamp: number): string => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };
    
    const formattedDate = computed(() => formatDate(props.date));
    const formattedClass = computed(() => {
        const year = new Date(props.date).getFullYear();
        const categoriesList = props.categories.map(category => category.title.toLowerCase()).join(' ');
        return `news ${categoriesList} ${year}`;
    });
  </script>
  