<template>
  <div class="lz-relative lz-inline-block" ref="dropdown" @click.stop>
    <button @click="toggleDropdown"
            class="lz-bg-white lz-border lz-border-solid lz-flex lz-h-[55px] lz-items-center lz-px-4 lz-rounded-[27.5px] lz-w-[175px]"
            :class="[hasSelected ?'lz-border-black': 'lz-border-gray-500']"
    >
      <span class="lz-transform lz-transition-transform lz-flex lz-items-center lz-ml-1 lz-mr-2.5" :class="{ 'lz-rotate-180': isOpen }">
        <lz-icon name="news-filter" class="lz-w-5 lz-h-5" />
      </span>
      <span>
      {{ label }}
      </span>
      <span v-if="itemCount > 0" class="lz-grow lz-flex lz-justify-end lz-mr-1">
          <span class="lz-w-[20px] lz-h-[20px] lz-leading-[20px] lz-text-[14px] lz-bg-primary lz-rounded-full lz-text-white ">{{ itemCount }}</span>
      </span>

    </button>

    <div v-if="isOpen" class="lz-fixed lz-inset-0 lz-z-50">

      <div class="lz-absolute lz-inset-0 lz-bg-black/40" @click="applyFilter"></div>

      <div class="lz-relative lz-bg-white lz-pointer-events-none lz-mx-auto lz-h-screen lz-w-full lz-overflow-hidden lz-transition-transform lz-duration-700 lz-pointer-events-auto">
        <div class="lz-w-full lz-flex lz-justify-end lz-px-5 lz-pt-6">
          <button @click="applyFilter" class="lz-font-semibold lz-text-[#e5251f] lz-bg-none lz-border-none lz-p-0.5 lz-h-6 lz-w-6 lz-cursor-pointer">
            <lz-icon name="close" class="lz-h-full lz-w-full" />
          </button>
        </div>

        <div class="lz-w-full lz-flex lz-justify-between lz-px-5">
          <h1 class="lz-m-0 lz-text-2xl">{{ t.filters.filterTitle }}</h1>
        </div>

        <div class="lz-overflow-y-auto lz-h-[calc(100dvh-(224px))]" :class="{'lz-border-b lz-border-b-gray lz-border-solid lz-border-t-0 lz-border-x-0': (yearFilterIsOpen ||  categoryFilterIsOpen)}">
          <div>
            <button @click="toggleFilter('years')" class="lz-justify-between lz-bg-white lz-border-none lz-flex lz-h-[55px] lz-items-center lz-w-full lz-font-bold lz-px-5 ">
              {{ t.filters.yearLabel }}
              <span class="lz-transform lz-transition-transform lz-flex lz-items-center lz-ml-1" :class="{ 'lz-rotate-180': yearFilterIsOpen }">
                <lz-icon name="mini-arrow" class="lz-w-[30px] lz-h-[30px]" />
              </span>
            </button>
            <div v-if="yearFilterIsOpen">
              <div v-for="item in yearItems" :key="itemYearKey(item)" class="lz-py-2 lz-px-5 hover:lz-bg-gray-100">
                <label class="lz-flex lz-items-center lz-m-0 lz-cursor-pointer">
                  <input type="checkbox" :value="itemYearKey(item)" v-model="internalSelectedYearItems" class="custom-checkbox" />
                  <p class="lz-m-0 lz-ml-5">{{ getItemTitle(item) }}</p>
                </label>
              </div>
            </div>
          </div>

          <hr />

          <div>
            <button @click="toggleFilter('categories')" class="lz-justify-between lz-bg-white lz-border-none lz-flex lz-h-[55px] lz-items-center lz-w-full lz-font-bold lz-px-5 ">
              {{ t.filters.categoryLabel }}
              <span class="lz-transform lz-transition-transform lz-flex lz-items-center lz-ml-1" :class="{ 'lz-rotate-180': categoryFilterIsOpen }">
                <lz-icon name="mini-arrow" class="lz-w-[30px] lz-h-[30px]" />
              </span>
            </button>
            <div v-if="categoryFilterIsOpen">
              <div v-for="item in categoryItems" :key="itemCategoryKey(item)" class="lz-py-2 lz-px-5 hover:lz-bg-gray-100">
                <label class="lz-flex lz-items-center lz-m-0 lz-cursor-pointer">
                  <input type="checkbox" :value="itemCategoryKey(item)" v-model="internalSelectedCategoryItems" class="custom-checkbox" />
                  <p class="lz-m-0 lz-ml-5">{{ getItemTitle(item) }}</p>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="lz-w-full lz-flex lz-justify-between lz-px-5 lz-border-l-none ">
          <button @click="clearFilter" class="lz-font-semibold lz-leading-[5.13] lz-text-[#808b8f] lz-bg-none lz-border-none lz-p-0 lz-cursor-pointer">
            {{ t.general.clearAllLabel }}
          </button>
          <button @click="applyFilter" class="lz-font-semibold lz-leading-[5.13] lz-text-[#e5251f] lz-bg-none lz-border-none lz-p-0 lz-cursor-pointer">
            {{ t.general.doneLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, watch, inject, computed} from 'vue';
  import LzIcon from './LzIcon.vue';

  const t = inject('translations');

  const props = defineProps({
    name: String,
    label: String,
    yearItems: {
      type: Array,
      default: () => [],
    },
    selectedYearItems: {
      type: Array,
      default: () => [],
    },
    itemYearKey: {
      type: Function,
      default: (item) => item.id,
    },
    categoryItems: {
      type: Array,
      default: () => [],
    },
    selectedCategoryItems: {
      type: Array,
      default: () => [],
    },
    itemCategoryKey: {
      type: Function,
      default: (item) => item.id,
    },
    getItemTitle: {
      type: Function,
      default: (item) => item.title || item,
    },
  });

  const emit = defineEmits(['update:selectedYearItems', 'update:selectedCategoryItems', 'applyFilter']);

  const isOpen = ref(false);
  const hasSelected = ref(false);
  const internalSelectedYearItems = ref([...props.selectedYearItems]);
  const internalSelectedCategoryItems = ref([...props.selectedCategoryItems]);
  const yearFilterIsOpen = ref(false);
  const categoryFilterIsOpen = ref(false);
  const itemCount = computed(() => props.selectedYearItems.length + props.selectedCategoryItems.length )

  watch(() => props.selectedYearItems, (newVal) => {
    internalSelectedYearItems.value = [...newVal];
      hasSelected.value = internalSelectedCategoryItems.value.length> 0 || newVal.length > 0;
  });

  watch(() => props.selectedCategoryItems, (newVal) => {
    internalSelectedCategoryItems.value = [...newVal];
    hasSelected.value = internalSelectedYearItems.value.length> 0 || newVal.length > 0;
  });


  function toggleDropdown() {
    isOpen.value = !isOpen.value;
    document.body.classList.toggle('lz-overflow-hidden', isOpen.value);
  }

  function toggleFilter(filter) {
    if (filter === 'years') {
      yearFilterIsOpen.value = !yearFilterIsOpen.value;
    } else if (filter === 'categories') {
      categoryFilterIsOpen.value = !categoryFilterIsOpen.value;
    }
  }

  function applyFilter() {
    emit('update:selectedYearItems', [...internalSelectedYearItems.value]);
    emit('update:selectedCategoryItems', [...internalSelectedCategoryItems.value]);
    emit('applyFilter');
    toggleFilter('years')
    toggleFilter('categories')
    closeDropdown();
  }

  function clearFilter() {
    internalSelectedYearItems.value = [];
    internalSelectedCategoryItems.value = [];
    applyFilter();
  }

  function closeDropdown() {
    isOpen.value = false;
    document.body.classList.remove('lz-overflow-hidden');
  }
</script>
