<template>
    <div class="lz-bg-gray lz-hidden lz-h-[4.375rem] lz-w-full lz-text-gray-400 lg:lz-block">
        <div
            class="lg:lz-flex lg:lz-justify-between container lz-text-sm before:lz-content-none after:lz-content-none lg:lz-h-full lg:lz-items-center">
            <div class="lz-flex lz-h-full lz-items-center lz-space-x-8">
                <a v-for="(top, i) in topNav" :key="`top-${i}`" :href="top.link"
                   class="lz-transition-colors hover:!lz-text-black">{{ top.title }}</a>
            </div>
            <div class="lz-flex lz-text-gray-400">
                <div class="lz-p-6 lz-cursor-pointer lz-relative lz-group hover:lz-text-black">
                    <lz-icon name="login" class="lz-fill-current lz-w-4 lz-h-4" />
                    <div
                        class="lz-absolute lz-z-50 -lz-bottom-4 lz-left-1/2 lz-opacity-0 lz-transition-opacity lz-pointer-events-none lz-whitespace-nowrap lz-px-4 lz-py-1 lz-shadow-md -lz-translate-x-1/2 lz-bg-black lz-text-white lz-text-sm group-hover:lz-opacity-100">
                        <div class="tooltip-arrow"></div>
                        Login
                    </div>
                </div>
                <div class="lz-w-px lz-bg-gray-400 lz-h-6 lz-self-center"></div>
                <div class="lz-p-6 lz-cursor-pointer lz-relative lz-group hover:lz-text-black">
                    <lz-icon name="sites" class="lz-fill-current lz-w-4 lz-h-4" />
                    <div
                        class="lz-absolute lz-z-50 -lz-bottom-4 lz-left-1/2 lz-opacity-0 lz-transition-opacity lz-pointer-events-none lz-whitespace-nowrap lz-px-4 lz-py-1 lz-shadow-md -lz-translate-x-1/2 lz-bg-black lz-text-white lz-text-sm group-hover:lz-opacity-100">
                        <div class="tooltip-arrow"></div>
                        English
                    </div>
                </div>
            </div>
        </div>
    </div>
    <lz-nav>
        <template
            #default="{
                isOpen,
                homeLink,
                toggle,
                toggleSearch,
                searchOpen,
                searchValue,
                setSearch,
                search,
                items
            }: {
                items: NavItem[],
                isOpen: boolean,
                homeLink: string,
                searchOpen: boolean,
                searchValue: string,
                search: (query: string) => void,
                setSearch: (value: string) => void,
                toggle: (event: MouseEvent | PointerEvent, index?: number) => void,
                toggleSearch: () => void
            }">
            <div
                class="nav-safe-area lz-sticky lz-left-0 lz-top-0 lz-z-40 lz-w-full lz-bg-white lz-px-4 lz-py-6 lg:lz-p-9">
                <div
                    class="lz-mx-auto lz-flex lz-items-center lg:lz-justify-between before:lz-content-none container lg:lz-h-10">
                    <button
                        class="lz-order-3 lz-ml-5 lz-flex lz-h-6 lz-w-6 lz-shrink-0 lz-items-center lz-justify-center lz-transition-colors lg:lz-order-none lg:lz-ml-0"
                        :class="{ 'lz-p-0.5': isOpen }"
                        @click="toggle">
                        <lz-icon :name="isOpen ? 'close' : 'burger'" class="lz-h-full lz-w-full" />
                    </button>
                    <a
                        :href="homeLink"
                        class="lz-block lz-order-1 -lz-mb-4 lz-h-7 lz-w-24 lz-shrink-0 lg:lz-order-none lg:lz-h-10 lg:lz-w-32 lg:lz-ml-8">
                        <lz-icon name="logo" class="lz-h-full lz-w-full" />
                    </a>
                    <div v-if="searchOpen"
                         class="lz-hidden lz-ml-16 lz-mr-auto lz-flex-1 lz-max-w-lg lg:lz-block">
                        <div
                            class="lz-border-b lz-border-black lz-pb-2 lz-w-full lz-flex lz-items-center lz-justify-between lz-mr-auto">
                            <input :value="searchValue" @input="setSearch($event.target.value)"
                                   @keydown.enter="search(`/author/de/searchResult?query=${encodeURI(searchValue)}`)"
                                   type="text"
                                   class="lz-bg-transparent lz-flex-1" placeholder="Suchbegriff eingeben">
                            <lz-icon @click="search(`/author/de/searchResult?query=${encodeURI(searchValue)}`)"
                                     name="search"
                                     :class="{ 'lz-fill-primary': searchValue.length }"
                                     class="lz-cursor-pointer lz-h-5 lz-w-5 lz-shrink-0 lz-ml-4" />
                        </div>
                    </div>
                    <div v-else-if="items && items.length"
                         class="lz-hidden lz-space-x-12 lg:lz-flex lg:lz-ml-16 lg:mr-4 lg:lz-flex-1">
                        <div
                            v-for="(mainItem, m) in items.filter((x) => x.highlight)"
                            :key="`${mainItem.title}#main`"
                            @click="toggle($event, m)"
                            class="hover:lz-text-primary lz-cursor-pointer lz-text-2xl">
                            {{ mainItem.title }}
                        </div>
                    </div>
                    <button
                        @click="toggleSearch"
                        class="lz-order-2 lz-ml-auto lz-flex lz-h-6 lz-w-6 lz-p-0.5 lz-shrink-0 lz-items-center lz-justify-center lz-transition-colors lg:lz-order-none lg:lz-ml-0">
                        <lz-icon :name="searchOpen ? 'close' : 'search'" class="lz-h-full lz-w-full" />
                    </button>
                </div>
            </div>
        </template>
        <template #nav="{ item, items, mobile, navigate, showItems, back, isBack }">
            <div class="lz-flex lz-px-4 lz-min-h-full lz-w-full lg:lz-px-0">
                <div v-if="items"
                     class="lz-hidden lz-w-full lg:lz-w-auto lg:lz-pt-44 lg:lz-flex lg:lz-flex-col lg:lz-items-start">
                    <ul class="lz-block lg:lz-pr-20">
                        <li
                            v-for="(itm, i) in items"
                            :key="'nav#' + i"
                            :class="[
                                    itm.highlight ? 'lz-mb-10 lz-font-bold lz-text-xl lg:lz-text-5xl' : 'lz-mb-6 lg:lz-text-2xl lg:lz-font-normal',
                                    { 'lg:lz-mt-16': !itm.highlight && i > 0 && items[i - 1].highlight }
                                ]"
                            class="hover:lz-text-primary lz-group lz-cursor-pointer lg:lz-w-96">
                            <div
                                v-if="itm.items && itm.items.length"
                                @mouseenter="navigate(i, true)"
                                class="lz-flex lz-items-center lz-justify-between">
                                <div
                                    class="lz-w-full lz-flex lz-justify-between lz-items-center"
                                    :class="{
                                            'lz-text-primary': itm.title === item.title
                                        }">
                                    {{ itm.title }}
                                    <div
                                        :class="[itm.title === item.title ? 'lz-opacity-100' : 'lz-opacity-0']"
                                        class="lz-relative lz-top-1 lz-ml-8 lz-h-4 lz-w-7 lz-shrink-0 lz-overflow-hidden lz-transition-opacity">
                                        <lz-icon
                                            name="arrow"
                                            class="lz-absolute lz-right-0 lz-h-4 lz-w-10 lz-fill-primary" />
                                    </div>
                                </div>
                            </div>

                            <a
                                v-else
                                :href="itm.link"
                                @mouseenter="navigate(i, true)"
                                :class="{
                                        'lg:lz-pr-16': itm.depth === 1
                                    }">
                                {{ itm.title }}
                            </a>
                        </li>
                    </ul>
                    <div class="lz-flex lz-space-x-12 lz-mt-auto lg:lz-pt-16 lg:lz-pb-20 lg:lz-text-sm">
                        <a v-for="(metaItem, m) in topNav" :key="`${metaItem.link}-mt-${m}`"
                           :href="metaItem.link">{{ metaItem.title }}
                        </a>
                    </div>
                </div>
                <Transition :name="isBack ? 'slide-left-to-right' : 'slide-right-to-left'">
                    <div v-if="mobile.items && mobile.items.length && showItems"
                         class="lz-text-sm lz-w-full lg:lz-hidden">
                        <div
                            v-if="mobile.depth > 0"
                            class="lz-group lz-relative lz-mb-6 lz-cursor-pointer"
                            @click="back">
                            <div
                                class="lz-absolute -lz-top-4 lz-h-2.5 lz-w-4 lz-overflow-hidden lz-transition-transform group-hover:lz--translate-x-2">
                                <lz-icon
                                    name="arrow"
                                    class="lz-h-2 lz-w-7 lz-rotate-180 lz-fill-gray-600" />
                            </div>
                            <div class="lz-text-primary lz-text-xl lz-font-bold">{{ mobile.title }}</div>
                            <div class="lz-text-gray-500 lz-underline lz-font-bold mt-1">Im Überblick</div>
                        </div>
                        <ul class="lz-block lg:lz-pr-28">
                            <li
                                v-for="(itm, m) in mobile.items"
                                :key="'nav#' + m"
                                :class="{
                                    'lz-font-bold lz-mb-5 lz-pb-5 lz-text-xl lz-border-b lz-border-gray-100': itm.highlight && itm.depth === 1,
                                    'lz-text-lg lz-mb-3': !itm.highlight && itm.depth === 1
                                }"
                                class="hover:lz-text-primary lz-group lz-cursor-pointer">
                                <div
                                    v-if="itm.items && itm.items.length"
                                    @click="navigate(m, false, true)"
                                    :class="{ 'lz-border-b lz-border-gray-100 lz-py-5': itm.depth > 1 }"
                                    class="lz-flex lz-items-center lz-justify-between">
                                    <div class="lz-flex lz-items-center">
                                        <div v-if="itm.icon"
                                             class="lz-bg-gray-100 lz-grow-0 lz-mr-5 lz-transition-colors lz-rounded-bl-lg lz-rounded-tr-lg group-hover:lz-bg-primary">
                                            <lz-icon :name="itm.icon"
                                                     class="lz-w-4 lz-h-4 lz-m-3 lz-fill-gray-300 group-hover:lz-fill-gray-100" />
                                        </div>
                                        {{ itm.title }}
                                    </div>
                                    <div
                                        class="lz-relative lz-top-1 lz-ml-8 lz-h-2.5 lz-w-4 lz-overflow-hidden lz-transition-transform group-hover:lz--translate-x-2">
                                        <lz-icon
                                            name="arrow"
                                            class="lz-absolute lz-right-0 lz-h-2 lz-w-7 lz-fill-current" />
                                    </div>
                                </div>

                                <a
                                    v-else
                                    :href="itm.link"
                                    class="lz-flex lz-items-center"
                                    :class="{
                                        'lz-border-b lz-border-gray-100 lz-py-5': itm.depth > 1
                                    }">
                                    <div v-if="itm.icon"
                                         class="lz-bg-gray-100 lz-grow-0 lz-mr-5 lz-transition-colors lz-rounded-bl-lg lz-rounded-tr-lg group-hover:lz-bg-primary">
                                        <lz-icon :name="itm.icon"
                                                 class="lz-w-4 lz-h-4 lz-m-3 lz-fill-gray-300 group-hover:lz-fill-gray-100" />
                                    </div>
                                    {{ itm.title }}
                                </a>
                            </li>
                        </ul>
                        <ul v-if="mobile.depth === 0" class="lz-block lz-text-gray-500 lz-mt-6">
                            <li v-for="(top, i) in topNav" :key="`top-mobile-${i}`" class="lz-mb-3">
                                <a :href="top.link">{{ top.title }}</a>
                            </li>
                        </ul>
                    </div>
                </Transition>
                <div
                    class="lz-hidden lz-bg-gray lz-w-96 lz-px-14 lg:lz-pt-44 lg:lz-block">
                    <div
                        v-if="item.depth === 2"
                        class="lz-group lz-relative lz-mb-14">
                        <div
                            @click="back"
                            class="lz-absolute lz-cursor-pointer -lz-top-6 lz-h-2.5 lz-w-4 lz-overflow-hidden lz-transition-transform hover:-lz-translate-x-2">
                            <lz-icon
                                name="arrow"
                                class="lz-h-2 lz-w-7 lz-rotate-180 lz-fill-gray-500" />
                        </div>
                        <div class="lz-text-primary lz-mb-2 lz-text-2xl">{{ item.title }}</div>
                        <a v-if="item.link" :href="item.link"
                           class="lz-text-gray-500 lz-underline lz-font-bold mt-1 hover:lz-underline">
                            Im Überblick
                        </a>
                    </div>
                    <ul :class="[item.depth === 1 ? 'lg:lz-text-base' : 'lg:lz-text-sm']">
                        <li
                            v-for="(subItem, s) in item.items"
                            :key="`sub#${subItem.title}`"
                            @click="navigate(s)"
                            class="hover:lz-text-primary lz-group lz-mb-6 lz-cursor-pointer lz-border-b lz-border-gray-600 lz-border-opacity-30 lz-pb-6">
                            <div
                                v-if="subItem.items && subItem.items.length"
                                class="lz-flex lz-items-center lz-justify-between">
                                <div class="lz-flex lz-items-center">
                                    <div v-if="subItem.icon"
                                         class="lz-bg-white lz-grow-0 lz-mr-5 lz-transition-colors lz-rounded-bl-lg lz-rounded-tr-lg group-hover:lz-bg-primary">
                                        <lz-icon :name="subItem.icon"
                                                 class="lz-w-5 lz-h-5 lz-m-4 lz-fill-gray-300 group-hover:lz-fill-gray-100" />
                                    </div>
                                    {{ subItem.title }}
                                </div>
                                <div
                                    class="lz-relative lz-top-0.5 lz-ml-8 lz-h-2.5 lz-w-4 lz-overflow-hidden lz-transition-transform group-hover:lz--translate-x-2">
                                    <lz-icon
                                        name="arrow"
                                        class="lz-absolute lz-right-0 lz-h-2 lz-w-7 lz-fill-current" />
                                </div>
                            </div>
                            <a v-else :href="subItem.link" class="lz-flex lz-items-center lz-group">
                                <div v-if="subItem.icon"
                                     class="lz-bg-white lz-grow-0 lz-mr-5 lz-transition-colors lz-rounded-bl-lg lz-rounded-tr-lg group-hover:lz-bg-primary">
                                    <lz-icon :name="subItem.icon"
                                             class="lz-w-5 lz-h-5 lz-m-4 lz-fill-gray-300 group-hover:lz-fill-gray-100" />
                                </div>
                                {{ subItem.title }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <template #sitenav="{ item }">
            <div v-if="item.depth === 0"
                 class="lz-fixed lz-bottom-0 lz-left-0 lz-border-t lz-border-gray-100 lz-w-full lz-flex lz-text-black lz-h-14 lz-bg-white lg:lz-hidden">
                <div class="lz-flex lz-items-center lz-p-4">
                    <lz-icon name="login" class="lz-fill-current lz-mr-2 lz-w-4 lz-h-4" />
                    <span class="lz-pl-px">Login</span>
                </div>
                <div class="lz-flex lz-items-center lz-p-4 lz-ml-5">
                    <lz-icon name="sites" class="lz-fill-current lz-mr-2 lz-w-4 lz-h-4" />
                    <span class="lz-pl-px">English</span>
                </div>
            </div>
        </template>
        <template
            #search="{ searchValue, setSearch, search }: { searchValue: string, setSearch: (value: string) => void, search: (query: string) => void }">
            <div class="lz-border-b lz-border-black lz-w-full lz-flex lz-pb-2 lz-items-center lz-justify-between">
                <input :value="searchValue"
                       @input="setSearch($event.target.value)"
                       @keydown.enter="search(`/author/de/searchResult?query=${encodeURI(searchValue)}`)"
                       type="text"
                       class="lz-bg-transparent lz-flex-1"
                       placeholder="Suchbegriff eingeben">
                <div @click="search(`/author/de/searchResult?query=${encodeURI(searchValue)}`)"
                     class="lz-cursor-pointer lz-h-6 lz-w-6 lz-shrink-0 lz-ml-4"
                     :class="{ 'lz-text-primary': searchValue.length }">
                    <lz-icon name="search"
                             class="lz-w-full lz-h-full lz-fill-current" />
                </div>
            </div>
        </template>
        <template #registration>
            <lz-nav-item
                v-for="navItem in mainNav"
                :key="navItem.title"
                :title="navItem.title"
                :link="navItem.link"
                :highlight="navItem.highlight"
                :depth="navItem.depth">
                <a :href="navItem.link">{{ navItem.title }}</a>
                <template #items v-if="navItem.items && navItem.items.length">
                    <lz-nav-item
                        v-for="subItem in navItem.items"
                        :key="subItem.title"
                        :title="subItem.title"
                        :icon="subItem.icon"
                        :depth="subItem.depth"
                        :link="subItem.link">
                        <a :href="subItem.link">{{ subItem.title }}</a>
                        <template #items v-if="subItem.items && subItem.items.length">
                            <lz-nav-item
                                v-for="subSubItem in subItem.items"
                                :key="subSubItem.title"
                                :title="subSubItem.title"
                                :depth="subSubItem.depth"
                                :link="subSubItem.link"></lz-nav-item>
                        </template>
                    </lz-nav-item>
                </template>
            </lz-nav-item>
        </template>
    </lz-nav>
</template>
<script setup lang="ts">
import LzNav from '@/components/nav/LzNav.vue'
import LzNavItem from '@/components/nav/LzNavItem.vue'
import { ref } from 'vue'
import type { Ref } from 'vue'
import type { NavItem } from '@/types'
import LzIcon from '@/components/base/LzIcon.vue'

const mainNav: Ref<NavItem[]> = ref([
    {
        title: 'Services',
        depth: 1,
        highlight: true,
        items: [
            { title: 'Consulting Digital Transformation', depth: 2 },
            { title: 'Human Centered Design', depth: 2 },
            {
                title: 'Software Engineering',
                depth: 2,
                icon: 'sites',
                link: '#',
                items: [
                    { title: 'Greenfield Project & New Digital Service', depth: 3 },
                    { title: 'Business Processes Automation', depth: 3 },
                    { title: 'Continuous Delivery & Deployment', depth: 3 },
                    { title: 'Operations & Operational Efficiency Optimisation', depth: 3 },
                    { title: 'System & Application Introductions & Migrations', depth: 3 },
                    { title: 'Multichannel Platform Solutions', depth: 3 },
                    { title: 'Legacy Transformation & Renewal Project', depth: 3 },
                    { title: 'Speedboat', depth: 3 },
                    { title: 'Low Code', depth: 3 },
                    { title: 'Technology & Security Architecture', depth: 3 }
                ]
            },
            { title: 'Cyber Security', depth: 2 },
            { title: 'Cloud Computing', depth: 2 },
            { title: 'Data & AI', depth: 2 },
            { title: 'Open Banking', depth: 2 },
            { title: 'Augumented Reality', depth: 2 },
            { title: 'Distributed Ledger Technology', depth: 2 }
        ]
    } as NavItem,
    {
        title: 'Produkte & Lösungen',
        depth: 1,
        highlight: true,
        items: [
            { title: 'Airlock® Secure Access Hub', depth: 2, icon: 'sites' },
            { title: 'Finanzlösungen', depth: 2 },
            { title: 'BIM-to-Field-Augmented', depth: 2 },
            { title: 'Inventory Management System', depth: 2 }
        ]
    },
    { title: 'Über uns', depth: 1, highlight: true },
    { title: 'Branchen', depth: 1 },
    { title: 'Referenzen', depth: 1 },
    { title: 'Karriere', depth: 1 }
])

const topNav = ref([
    { title: 'Jobs', link: '#' },
    { title: 'News', link: '#' },
    { title: 'Kontakt', link: '#' }
])
</script>
