<template>
    <slot />
    <slot name="items"></slot>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, provide, ref, useSlots } from 'vue'
import type { NavItem } from '@/types'

const props = defineProps<NavItem>()
const registerMain = inject<(item: NavItem) => void>('registerMainNavItem')
const registerSub = inject<(item: NavItem) => void>('registerSubNavItem', () => undefined)
const items = ref<NavItem[]>([])
const slots = useSlots()
const isActive = computed(() => (props.link && location.pathname === props.link) || items.value.some(x => x.active));

provide('registerSubNavItem', registerSubNavItem)

onMounted(() => {
    if (!slots.items || slots.items({}).length === 0) {
        // no sub-items -> register directly
        registerSelf()
    }
})

function registerSubNavItem(item: NavItem) {
    // check depth to only register direct children
    // since provide is passed down to all descendants
    if (item.depth === props.depth + 1) {
        items.value.push(item)
    }
    if (items.value.length === slots.items({}).length) {
        // register after all sub-items have been registered
        registerSelf()
    }
}

function registerSelf() {
    // distinguish main nav items from rest
    if (props.depth === 1) {
        registerMain({ ...props, items: items.value, active: isActive.value })
    } else {
        registerSub({ ...props, items: items.value, active: isActive.value })
    }
}
</script>
