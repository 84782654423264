<template>
    <div class="lz-relative lg:lz-h-service lg:lz-flex lz-m-auto lz-max-w-[1800px]" ref="el">
        <div
            class="lz-mb-12 lz-flex lz-flex-col lz-justify-center lg:lz-mb-0 lg:lz-transition-opacity lg:lz-w-96 lg:lz-duration-300 lg:lz-pointer-events-none lg:lz-absolute lg:lz-top-0 lg:lz-left-0 lg:lz-z-10 lg:lz-h-full"
            :class="[allClosed ? 'lz-delay-700 lz-opacity-100' : 'lg:lz-opacity-0']">
            <slot name="intro" />
        </div>
        <div class="lg:lz-transition-all lz-duration-500 lz-w-full" :class="{ 'lg:lz-pl-80': allClosed }">
            <ul ref="container">
                <slot :toggle="toggle" />
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, provide, type Ref, ref, useSlots } from 'vue'

const slots = useSlots()
/* refs */
const active = ref(-1)
const el: Ref<HTMLElement | null> = ref(null)
const container: Ref<HTMLElement | null> = ref(null)
const closedContainerWidth = ref(0)
const openContainerWidth = ref(0)
/* computed */
// eslint-disable-next-line dot-notation
const children = computed(() => slots.default({}) ? slots.default({}).filter(x => x.type && x.type['__name'] === 'LzAccordion') : [])
const allClosed = computed(() => active.value < 0)
const containerWidth = computed(() => active.value < 0 ? closedContainerWidth.value : openContainerWidth.value)
const activeId = computed(() => children.value.length > active.value ? children.value[active.value].props.id : '')
/* provides */
provide('activeId', activeId)
provide('numTabs', children.value.length)
provide('allClosed', allClosed)
provide('containerWidth', containerWidth)

/* lifecycle hooks */
onMounted(() => {
    getWidths()
    window.addEventListener('resize', getWidths)
    document.addEventListener('click', onOutsideClick)
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', getWidths)
    document.removeEventListener('click', onOutsideClick)
})

function getWidths() {
    openContainerWidth.value = el.value.getBoundingClientRect().width
    closedContainerWidth.value = openContainerWidth.value - 320;
}

function toggle({ id, open, closeOthers }: { id: string; open: boolean, closeOthers: boolean }) {
    if (open) {
        active.value = children.value.findIndex(c => c.props.id === id)
    } else if (closeOthers) {
        active.value = -1
    }
}

function onOutsideClick(evt: MouseEvent) {
    let targetElement = evt.target

    do {
        if (targetElement === el.value) {
            return
        }
        targetElement = (targetElement as HTMLElement).parentNode
    } while (targetElement)
    active.value = -1
}
</script>
