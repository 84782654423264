import { computed, provide, ref } from 'vue'
import type { NavItem } from '@/types'

/**
 * generic navigation-related logic, commonly used in navigation components
 * independent of styling
 */
export function useNavigation() {
    const items = ref<NavItem[]>([])
    // stored the currently open nav item as a series of indices,
    // so we can go back by just removing the last one.
    const path = ref<number[]>([])
    const mobilePath = ref<number[]>([])
    const isTop = ref(true)
    const isOpen = ref(false)
    const showNavi = ref(true)

    // returns the active item based on `path`
    const currentItem = computed<NavItem>(() => {
        let item = { title: '', depth: 0, items: items.value } as NavItem
        path.value.forEach((x) => {
            if (item.items && item.items.length > x) {
                item = item.items[x]
            }
        })
        return item
    })

    // returns the active item based on `path` for mobile
    const currentMobileItem = computed<NavItem>(() => {
        let item = { title: '', depth: 0, items: items.value } as NavItem
        mobilePath.value.forEach((x) => {
            if (item.items && item.items.length > x) {
                item = item.items[x]
            }
        })
        return item
    })

    // we can go back as long as we're at least 1 level deep
    const hasBack = computed(() => !!mobilePath.value.length)

    function registerMainNavItem(item: NavItem) {
        items.value.push(item)
    }

    function navigate(index: number, replace = false, mobile = false) {
        if (replace) {
            // replace the whole path
            if (mobile) {
                mobilePath.value = [index]
            } else {
                path.value = [index]
            }
        } else {
            if (mobile) {
                mobilePath.value.push(index)

            } else {
                path.value.push(index)
            }
        }
    }

    function back() {
        path.value.pop()
        mobilePath.value.pop()
    }

    function toggle() {
        isOpen.value = !isOpen.value
        if (!isOpen.value) {
            // reset menu on close
            path.value = []
            mobilePath.value = []
        }
    }

    provide('registerMainNavItem', registerMainNavItem)

    return { currentItem, items, hasBack, isTop, isOpen, showNavi, path, mobilePath, navigate, back, toggle, currentMobileItem }
}
