<template>
    <li ref="el" :class="{ 'lz-text-white': contrast }">
        <!-- Mobile -->
        <div class="lz-relative lz-py-8 -lz-mx-4 lz-px-4 lz-text-sm lg:lz-hidden"
             :class="background"
             :style="{ backgroundColor }"
             data-analytics-event-name="subject_click"
             data-analytics-event-category="Engagement"
             data-analytics-element-type="Service-Phase"
             :data-analytics-element-detail="title"
        >
            <div @click="toggle(false)"
                 class="lz-flex lz-justify-between lz-text-lg lz-items-center lz-font-bold">
                <span :class="{ 'lz-opacity-60': !visible }">{{ title }}</span>
                <lz-icon :name="visible ? 'minus' : 'plus'" class="lz-w-4 lz-h-4" />
            </div>
            <collapse-transition>
                <div v-if="visible" class="lz-py-4">
                    <slot />
                </div>
            </collapse-transition>
            <div
                v-if="!isLastChild"
                class="lz-w-6 lz-h-6 lz-rotate-45 lz-translate-y-1/2 -lz-translate-x-1/2 lz-absolute lz-z-10 lz-left-1/2 lz-bottom-0 lz-duration-500 lz-transition-all"
                :class="[background, { '-lz-translate-y-full lz-opacity-0': visible }]"
                :style="{ backgroundColor }"></div>
        </div>
        <!-- Desktop -->
        <div class="lz-hidden lg:lz-block lg:lz-h-service lz-py-4 lz-float-left lz-overflow-hidden"
             :class="background"
             :style="{ transition: 'all 0ms ease', width, backgroundColor, transitionDelay: allClosed ? '0' : '100ms', transitionDuration: allClosed ? '300ms' : '600ms' }">
            <div class="lz-h-full lz-relative">
                <slot :width="contentWidth" />
            </div>
        </div>
        <div
            :class="[background, { 'lz-w-28': !allClosed }]"
            :style="{ backgroundColor, ...tabWidth }"
            @click="toggle"
            class="lz-group lz-hidden lz-relative lg:lz-block lz-cursor-pointer lg:lz-h-service lz-float-left lz-text-center lz-transition-all lz-duration-700"
            data-analytics-event-name="subject_click"
            data-analytics-event-category="Engagement"
            data-analytics-element-type="Service-Phase"
            :data-analytics-element-detail="title"
        >
            <div
                class="lz-absolute lz-top-0 lz-right-0 lz-w-1/2 lz-z-20 lz-h-full lz-border-solid lz-border-0 lz-border-r-4 lz-origin-left lz-transition-transform lz-duration-400 lz-ease-in-out"
                :class="[background, visible ? 'lz-border-primary' : 'lz-border-transparent group-hover:lz-translate-x-4']"
                :style="{ backgroundColor }">
                <div
                    v-if="!isLastChild"
                    class="lz-w-6 lz-h-6 lz-rotate-45 lz-absolute lz-z-30 -lz-right-4 lz-top-8 lz-duration-500 lz-transition-transform"
                    :class="[background, { '-lz-translate-x-full': visible }]" :style="{ backgroundColor }"></div>
            </div>
            <div
                :class="[visible ? 'lz-text-primary' : `${contrast ? 'lz-text-white' : 'lz-text-gray-600'}`, { 'lz-opacity-60': !visible && !allClosed }]"
                class="lz-absolute lz-right-8 lz-pr-16 lz-z-20 lz-whitespace-nowrap lz-font-bold lz-text-2xl lz-origin-right -lz-rotate-90 lz-transition-all group-hover:lz-opacity-100 group-hover:lz-text-primary">
                {{ title }}
            </div>
        </div>
    </li>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, Ref, computed, defineProps, inject, ref, withDefaults, watch } from 'vue'
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import events from '@/events'
import LzIcon from '@/components/base/LzIcon.vue'

const ANIM_DURATION = 1500

const props = withDefaults(defineProps<{
    id: string;
    title?: string;
    group?: string;
    open?: boolean;
    disabled?: boolean;
    small?: boolean;
    horizontal?: boolean;
    background?: string;
    contrast?: boolean;
    customColor?: string;
}>(), {
    title: '',
    group: '',
    open: false,
    disabled: false,
    small: false,
    horizontal: true,
    contrast: false,
    background: 'lz-bg-service-1'
})

const emit = defineEmits<{
    (e: 'toggle', payload: { id: string; open: boolean, closeOthers: boolean }): void
}>()

/* refs */
const el: Ref<HTMLElement | null> = ref(null)
const visible = ref(props.open)
const contentVisible = ref(false)
/* injects */
const activeId: Ref<string> = inject('activeId')
const numTabs: number = inject('numTabs')
const allClosed: Ref<boolean> = inject('allClosed')
const containerWidth: Ref<number> = inject('containerWidth')
/* computed */
const isLastChild = computed(() => el.value ? !el.value.nextElementSibling : true)
const tabWidth = computed(() => allClosed.value ? { width: `${containerWidth.value / numTabs}px` } : {})
const width = computed(() => visible.value ? `${containerWidth.value - (112 * numTabs)}px` : '0px')
const contentWidth = computed(() => `${containerWidth.value - (112 * numTabs)}px`)
const backgroundColor = computed(() => props.customColor ? props.customColor : '')

watch(allClosed, () => {
    if (allClosed.value) {
        visible.value = false
    }
})

defineExpose({ visible })

events.$on('accordion-open', onAccordionOpened)
/* lifecycle hooks */
// eslint-disable-next-line no-undef
onMounted(() => {
    window.addEventListener('resize', checkOpenState)
})

// eslint-disable-next-line no-undef
onBeforeUnmount(() => {
    window.removeEventListener('resize', checkOpenState)
})


// in mobile multiple tabs can be opened, so when we switch back to desktop (or whenever
// the screen size changes, close all but one tab
function checkOpenState() {
    visible.value = visible.value && props.id === activeId.value
}

function onAccordionOpened(group: string) {
    if (!props.group || props.group === '') {
        return
    }
    if (props.group === group) {
        visible.value = false
        contentVisible.value = false
    }
}

function toggle(closeOthers = true) {
    if (!visible.value && closeOthers) {
        events.$emit('accordion-open', props.group)
    } else {
        contentVisible.value = false
    }
    visible.value = !visible.value
    setTimeout(() => {
        contentVisible.value = visible.value
    }, ANIM_DURATION)
    emit('toggle', { id: props.id, open: visible.value, closeOthers })
}
</script>
