import { createApp } from 'vue'
import ergon from './ergon'

const app = createApp({})

app.use(ergon)

// IMPORTANT FOR GREENBARS
app.config.compilerOptions.comments = true

app.mount('#app')

declare module 'vue' {
    interface ComponentCustomProperties {
        $contextPath: string
    }
}

declare global {
    interface Window {
        contextPath: string;
        dataLayer: {
            push: (payload: unknown) => void;
        }
    }
}
