<template>
  <span
    v-for="item in items"
    :key="itemKey(item)"
    class="lz-flex lz-items-center lz-bg-[#1e1e1e] lz-text-white lz-rounded-full lz-px-4 lz-mr-2.5 lz-mb-3 hover:lz-bg-opacity-80"
  >
    {{ itemTitle(item) }}
    <span @click="() => removeItem(item)" class="lz-ml-2 lz-cursor-pointer lz-text-[1.4rem]/[1.4rem]">
      &times;
    </span>
  </span>
</template>

<script setup>
  const props = defineProps({
    items: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: Function,
      default: (item) => item,
    },
    getItemTitle: {
      type: Function,
      default: (item) => item,
    },
  });

  const emit = defineEmits(['removeItem']);

  const itemTitle = (item) => props.getItemTitle(item);

  const removeItem = (item) => {
    emit('removeItem', item);
  };
</script>
