<template>
    <slot
        :is-top="isTop"
        :is-open="isOpen"
        :home-link="homeLink"
        :show-navi="showNavi"
        :items="currentItem.items || []"
        :toggle-search="toggleSearch"
        :search-open="searchOpen"
        :search-value="searchValue"
        :search="search"
        :set-search="setSearchValue"
        :is-menu-fixed="isMenuFixed"
        :toggle="toggleMenu" />
    <div
        class="lz-fixed lz-pointer-events-none lz-left-0 lz-top-0 lz-z-50 lz-pt-[4.5rem] lz-h-screen lz-w-full lz-overflow-hidden lz-transition-colors lz-duration-700 lg:lz-pt-0 lg:lz-max-h-screen"
        @click.self="toggleMenu"
        :class="[isOpen ? 'lz-max-h-screen lg:lz-bg-black/40' : 'lz-max-h-0 lz-bg-transparent', searchOpen ? 'lz-max-h-screen lg:lz-max-h-0' : 'lz-max-h-0 lz-bg-transparent', { 'lg:lz-pointer-events-auto': showItems }]">
        <TransitionGroup :name="isOpen ? 'slide-left-to-right' : 'slide-right-to-left'">
            <div
                key="menu"
                v-if="isOpen"
                class="lz-pointer-events-auto lz-flex lz-h-full lz-items-start lz-pt-10 lz-justify-between lz-bg-white lz-pb-4 lg:lz-pt-0 lg:lz-py-0 lg:lz-inline-flex lg:lz-pl-40">
                <button
                    class="lz-absolute lz-top-6 lz-right-4 lz-hidden lg:lz-flex lz-h-6 lz-w-6 lz-shrink-0 lz-items-center lz-justify-center lz-p-0.5 lz-transition-colors lg:lz-top-12 lg:lz-right-auto lz-border-0"
                    data-analytics-event-name="subject_click"
                    data-analytics-event-category="Engagement"
                    data-analytics-element-type="Navigation-Close"
                    @click="toggleMenu">
                    <lz-icon name="close" class="lz-h-full lz-w-full" />
                </button>
                <slot
                    name="nav"
                    :back="backClicked"
                    :item="currentItem"
                    :items="items"
                    :mobile="currentMobileItem"
                    :path="path"
                    :show-items="showItems"
                    :has-back="hasBack"
                    :is-back="isBack"
                    :navigate-hover="onItemHover"
                    :navigate="itemClicked" />
                <slot name="teaser" :show="!showSiteNav" />
                <Transition name="slide-bottom-to-top">
                    <slot
                        name="sitenav"
                        :toggle="toggleSiteNav"
                        :index="siteNavIndex"
                        :item="currentMobileItem"
                        :select="selectSiteNav" />
                </Transition>
            </div>
            <div
                key="search"
                v-if="searchOpen"
                class="lz-pointer-events-auto lz-px-4 lz-flex lz-h-full lz-items-start lz-pt-10 lz-justify-between lz-overflow-y-auto lz-overflow-x-hidden lz-bg-gray-100 lz-pb-4 lg:lz-hidden">
                <button
                    class="lz-absolute lz-top-6 lz-right-4 lz-hidden lg:lz-flex lz-h-6 lz-w-6 lz-shrink-0 lz-items-center lz-justify-center lz-p-0.5 lz-transition-colors lg:lz-top-12 lg:lz-right-auto lz-border-0"
                    @click="toggleMenu">
                    <lz-icon name="close" class="lz-h-full lz-w-full" />
                </button>
                <slot name="search" :is-menu-fixed="isMenuFixed" :set-search="setSearchValue" :search="search" :search-value="searchValue" />
            </div>
        </TransitionGroup>
    </div>
    <div class="lz-hidden">
        <slot name="registration" />
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useNavigation } from '@/composables/useNavigation'
import LzIcon from '@/components/base/LzIcon.vue'
import { checkIsMobile, dataLayerPush, lang } from '@/utils'
import { NavItem } from '@/types'

const ANIM_TIME = 25
const TOP_HEADER_SIZE = 70

// generic navigation logic comes from composable
const {
    currentItem,
    items,
    hasBack,
    isTop,
    isOpen,
    path,
    showNavi,
    currentMobileItem,
    navigate,
    back,
    toggle
} =
    useNavigation()
const contextPath = inject('contextPath')
const homeLink = computed(() => `${contextPath}/${lang()}`)

// project specific stuff
const showSiteNav = ref(false)
const siteNavIndex = ref(0)
const showItems = ref(false)
const isBack = ref(false)
const searchOpen = ref(false)
const searchValue = ref('')
const isMenuFixed = ref(false)
const isMobile = ref(checkIsMobile())

// temporarily hide nav items for animation
function itemClicked(index: number, replace = false, mobile = false) {
    isBack.value = false
    showItems.value = false
    setTimeout(() => {
        navigate(index, replace, mobile)
        showItems.value = true
    }, ANIM_TIME)
}

function onItemHover(index: number, replace: boolean) {
    if (items.value.length > index) {
        const item: NavItem = items.value[index]
        dataLayerPush({
            'event': 'subject_click',
            'event_category': 'Engagement',
            'element_type': 'Navigation-Menu',
            'element_detail': item.title,
            'element_topic': item.depth
        })
    }
    navigate(index, replace)
}

function backClicked() {
    isBack.value = true
    showItems.value = false
    setTimeout(() => {
        back()
        showItems.value = true
    }, ANIM_TIME)
}

function toggleSiteNav() {
    isBack.value = showSiteNav.value
    if (showSiteNav.value) {
        showSiteNav.value = false
        siteNavIndex.value = 0
        setTimeout(() => {
            showItems.value = true
        }, ANIM_TIME)
    } else {
        showItems.value = false
        setTimeout(() => {
            showSiteNav.value = true
        }, ANIM_TIME)
    }
}

function selectSiteNav(index: number) {
    siteNavIndex.value = index
}

function toggleMenu(event: MouseEvent | PointerEvent, index = -1) {
    isBack.value = !isOpen.value
    searchOpen.value = false
    if (isOpen.value) {
        showItems.value = false
        showSiteNav.value = false
        siteNavIndex.value = 0
        setTimeout(() => {
            toggle()
            document.body.classList.toggle('lz-overflow-hidden', isOpen.value)
        }, ANIM_TIME)
    } else {
        toggle()
        document.body.classList.toggle('lz-overflow-hidden', isOpen.value)
        const activeIndex =items.value.findIndex(x => x.active)
        navigate(index >= 0 ? index : (activeIndex >= 0 ? activeIndex : 0))
        setTimeout(() => {
            showItems.value = true
        }, ANIM_TIME)
    }
}

function search(query: string) {
    location.href = query
}

function setSearchValue(value: string) {
    searchValue.value = value
}

function toggleSearch() {
    searchOpen.value = !searchOpen.value
    isOpen.value = false
    nextTick(() => {
        const searchInputElement: string = isMobile.value ? '.nav-border input' : '.nav-safe-area input'
        const searchElement = document.querySelector(searchInputElement) as HTMLElement
        if(searchElement){
            searchElement.focus()
        }
    })
}
function windowResized() {
    isMobile.value = checkIsMobile();
}
function updateHeaderOnScroll() {
    const fixedMenuStartsAt: number = isMobile.value ? 0 : TOP_HEADER_SIZE;
    isMenuFixed.value = window.scrollY > fixedMenuStartsAt;
}
onMounted(() => {
    document.querySelector("#nav-placeholder").remove()
    window.addEventListener('scroll', updateHeaderOnScroll)
    window.addEventListener('resize', windowResized)
})
onUnmounted(() => {
    window.removeEventListener('scroll', updateHeaderOnScroll)
    window.removeEventListener('resize', windowResized)
})
</script>
