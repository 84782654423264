<template>
    <div 
      class="lz-inline-block lz-h-8 lz-w-8 lz-animate-spin lz-rounded-full lz-border-4 lz-border-solid lz-border-current lz-border-e-transparent lz-text-surface lz-motion-reduce:lz-animate-[spin_1.5s_linear_infinite] lz-dark:lz-text-white" 
      role="status" 
      aria-label="Loading"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </template>
  
  <script lang="ts" setup>
  </script>
  