import mitt from 'mitt'

type Event = {
    name: string
    payload: unknown
}

const emitter = mitt<Event>()

export default {
    $on: (name, payload: any) => emitter.on(name, payload),
    $off: (name, payload: any) => emitter.off(name, payload),
    $emit: (name, payload: any) => emitter.emit(name, payload)
}
