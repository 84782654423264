<template>
    <svg>
        <use :xlink:href="`${contextPath}${sprite}#${name}`" style="pointer-events: none"></use>
    </svg>
</template>

<script lang="ts" setup>
    import { withDefaults, defineProps, computed, inject } from 'vue'

    const props = withDefaults(
        defineProps<{
            name: 'arrow' | 'burger' | 'chevron' | 'login' | 'logo' | 'search' | 'sites' | 'close' | 'plus' | 'minus' | 'mini-'
            source?: string
        }>(),
        {
            source: '/img/sprite.svg'
        }
    )
    const contextPath = inject('contextPath')

    const sprite = computed(() => '/.resources/ergon/webresources' + props.source)
</script>
